import * as React from 'react';

import { useSelector } from 'react-redux';
import { useLocation, useNavigate, Location } from 'react-router-dom';
import LoginForm from './LoginForm';

import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import { useCases, clearErrors, authSelectors as select } from '../../../../core/auth';
import { AuthLayout } from '../Layout';
import AuthLayoutLink from '../Layout/AuthLayoutLink';

function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoading = useSelector(select.selectIsLoadingAuth);
  const hasError = useSelector(select.selectAuthErrors);

  // const from = (location.state as any)?.from?.pathname || `planning`;

  const login = (userLogginDetails: { username: string; password: string }) => {
    const { username, password } = userLogginDetails;
    dispatch(useCases.userLogin({ name: username, password }))
      .then((res) => {
        console.log(res.payload)
        navigate(prepareRedirectionUrl(res?.payload?.user, location), { replace: true })
      });
  };

  const prepareRedirectionUrl = (user: { roles: string[] }, location: Location) => {
    const from = (location.state as any)?.from?.pathname || `planning`;
    // console.log(user?.roles);
    if (!user || !user?.roles?.length) return '/';
    const userRole = user?.roles?.[0];
    return userRole && !from.split('/')[1].includes(userRole)
      ? `/${userRole.concat('/', from)}`
      : from;
  }

  React.useEffect(() => {
    setTimeout(() => {
      if (hasError.length > 0) dispatch(clearErrors());
    }, 4000);
  }, [dispatch, hasError]);

  return (
    <AuthLayout title="Connexion" errors={hasError}>
      <LoginForm login={login} isLoading={isLoading} />
      <AuthLayoutLink to="/reset-password" text="Mot de passe oublié ?" />
    </AuthLayout>
  );
}

export default Login;
