import axios, { AxiosInstance } from 'axios';
import httpService from './axios.config';

const httpProvider = httpService;

interface MethodHttp {
	<T = any, D = Error>(path: string, config?: any): Promise<T>;
}

interface HttpProvider {
	get: MethodHttp;
	post: MethodHttp;
	delete: MethodHttp;
	patch: MethodHttp;
	put: MethodHttp;
	defaults: any;
}

const httpApiAdapter = (http = httpProvider): HttpProvider => {
	function get<T = any>(path: string, ...options: any[]) {
		return _requestHandler<T>('get', path, ...options);
	}

	function post<T>(path: string, ...options: any[]) {
		return _requestHandler<T>('post', path, ...options);
	}

	function put<T>(path: string, ...options: any[]) {
		return _requestHandler<T>('put', path, ...options);
	}

	async function _requestHandler<T = any>(
		methods: keyof typeof httpProvider,
		path: string,
		...options: any[]
	): Promise<T>;

	async function _requestHandler(method: keyof typeof httpProvider, path: string, ...options: any[]) {
		console.log(path)
		try {
			const result = await (http as any)[method](path, ...options);
			if (result.data) return result.data;
			return result;
		} catch (error) {
			if (axios.isAxiosError(error)) {
				if ('response' in error) throw error.response?.data;
			}
			console.warn(error);
			console.warn((error as any).message);
			throw new Error("API_ERROR : unhandled Exception");
		}
	}

	return {
		get,
		post,
		delete: http.delete,
		put: put,
		patch: http.patch,
		defaults: (http as AxiosInstance).defaults
	};
};

export default httpApiAdapter;

export const _apiUrlFactory = (prefix: string) => (path: string = '') => `${prefix}${path ? '/' + path : ''}`;

