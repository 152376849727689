import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { userLogin, logout, authenticateUser } from './use-cases';
import tokenStorageService from '../../infra/storage-provider/token-storage.service';

interface IAuthInitialState {
	isLoggedIn: boolean;
	loading: string;
	user: any;
	errors: any[]
};

const authInitialState: IAuthInitialState = {
	isLoggedIn: false,
	loading: 'idle',
	user: undefined,
	errors: [] as any[]
};

export const authSlice = createSlice({
	name: 'auth',
	initialState: authInitialState,
	reducers: {
		logout: (state) => {
			state.loading = 'idle';
			state.isLoggedIn = false;
			state.user = undefined;
		},
		clearErrors: (state) => {
			state.errors = [];
		}
	},
	extraReducers: (builder) =>
		builder
			.addCase(userLogin.rejected, (state, action) => {
				const error = action.payload || action.error;
				state.errors.push(error);
				if (state.loading === 'pending') {
					authSlice.caseReducers.logout(state);
				}
			})
			.addCase(authenticateUser.rejected, (state, action) => {
				if (state.loading === 'pending') { state.loading = 'idle' }
				state.isLoggedIn = false;
			})
			.addCase(logout.fulfilled, (state) => {
				authSlice.caseReducers.logout(state);
			})
			.addMatcher(isAnyOf(authenticateUser.pending, userLogin.pending), (state, action) => {
				if (state.loading === 'idle') {
					state.loading = 'pending';
				}
			})
			.addMatcher(isAnyOf(authenticateUser.fulfilled, userLogin.fulfilled), (state, action) => {
				console.log(action.payload)
				tokenStorageService.set().all({
					access: { token: action.payload?.access?.token || action.payload?.token },
					refresh: { token: action.payload?.refresh?.token }
				});
				if (state.loading === 'pending') {
					state.loading = 'idle';
					state.isLoggedIn = true;
					state.user = action.payload.user;
				}
			})
});

export const { clearErrors } = authSlice.actions;
export default authSlice.reducer;
