import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AppRoute from './app/AppRoute';
import { ThemeProvider } from '@mui/material';
import theme from './theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <AppRoute />
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
