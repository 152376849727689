import httpApiAdapter, { _apiUrlFactory } from "../api-provider";
import { Planning } from "../../core/planning/entities/planning.entity";

const httpService = httpApiAdapter();
const planningBaseUrl = _apiUrlFactory("planning");

type Consignes = {
	consignes: {
		chauffeur?: string;
		evenement?: string;
	}
}

export const getAll = async () => {
    const planning = await httpService.get(planningBaseUrl());
    return planning.map((p: Planning & Consignes) => _mapToEntity(p));
};

export const getByDate = async (seekingDate: string) => {
    const searchParam = seekingDate ? `?search=${seekingDate}` : '';
    const planning = await httpService.get(planningBaseUrl() + searchParam);
    return planning.map((p: Planning & Consignes) => _mapToEntity(p));
}

// utils
const _mapToEntity = (p: Planning & Consignes): Planning => {
    console.log(p.consignes);
	return {
		...p,
		consignes_chauffeur: p.consignes?.chauffeur,
		evenement: p.consignes?.evenement
	};
};