import { createAsyncThunk } from "@reduxjs/toolkit";
import { ROLES } from "../../roles.enum";
// import { useCases as entrepriseUseCase } from "../../entreprise";
// import { useCases as chantierUseCases } from "../../chantier";


export const userLogin = createAsyncThunk(
    "[AUTH] Login",
    async (userCredentials: { name: string, password: string }, thunk: any) => {
        const { name: username, password } = userCredentials;
        try {
            const user = await thunk.extra.authService.login(username, password);
            console.log(user.user);
            console.log(user);
            if (!user || user?.user?.roles?.includes(ROLES.ADMIN)) { throw new Error('Cette application n\'est pas encore disponible pour les administrateurs') }
            return user;
        } catch (error: any) {
            const errorMessage = error?.statusCode === 403 ? 'mot de passe ou nom d\'utilisateur incorrect' : error?.message;
            return thunk.rejectWithValue({ message: errorMessage })
        }
    })

