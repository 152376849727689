import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './core/store';
import { Provider } from 'react-redux';
import tokenStorageService from './infra/storage-provider/token-storage.service';
import { useCases } from './core/auth';

const refreshToken = tokenStorageService.get().refresh() || '';
if (!!refreshToken) {
  console.log(refreshToken)
  store.dispatch(useCases.authenticateUser(refreshToken))
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
