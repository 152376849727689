import { BonEntity } from "../../core/bon/entities/bon.entity";
import httpApiAdapter, { _apiUrlFactory } from "../api-provider";
import { BonApi, _getQueryParamsToUrl, _mapBonApiToEntity } from "./bon-api.utils";

const planningBaseUrl = _apiUrlFactory("bon");
const httpService = httpApiAdapter();

export const getBonById = async (id: string) => {
    return httpService.get(planningBaseUrl(id));
}

export const startProcedure = async (id: string, _bonDetails: { fraisAutoroute: number, observation: string, immatriculation: string }) => {
    return httpService.post(planningBaseUrl(`${id}/procedure/start`), _bonDetails);
}

interface GetArgs {
    filters: BonFilters;
    fromDate: string;
    toDate: string;
    query?: string;
}

interface BonFilters {
    fournisseur: string | string[];
    chantier: string | string[];
    client: string | string[];
    interlocuteurClient: string | string[];
    article: string | string[];
    status: string | string[];
}


export const getListOfBons = async (options: GetArgs) => {
    try {
        const _urlParams = _getQueryParamsToUrl(options);
        const listOfBOns = await httpService.get<BonApi[]>(planningBaseUrl() + _urlParams);
        return listOfBOns.map(bon => _mapBonApiToEntity(bon));
    } catch (e) {
        console.log("cannot load list of bons ", e);
        return [];
    }
}




