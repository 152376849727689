import { createAsyncThunk } from "@reduxjs/toolkit";

interface IRequestBonOptionsQuery {
    filters?: object;
    fromDate?: string;
    toDate?: string;
    // query?: string;
}

export const retreiveListOfSignableBons = createAsyncThunk(
    '[BON] retreiveListOfSignableBons',
    async (payload: IRequestBonOptionsQuery, thunkAPI) => {
        const { bonService } = thunkAPI.extra as any;
        const listOfBons = await bonService.getListOfBons(payload);
        console.log(listOfBons);
        return listOfBons;
    }
)